<template>
  <div class="d-flex justify-space-between row stepper-header">
    <template v-for="(step, i) in steps">
      <v-divider v-if="i !== 0" :key="`step-divider-${i}`"></v-divider>
      <div
        :key="`step-${i}`"
        class="step"
        :class="stepClass(i)"
        @click="changeStep(i)"
      >
        <div class="step-content text-center">
          <v-avatar size="41" class="sw-accent-bg">
            <span class="sw-on-accent font-weight-medium">{{ i + 1 }}</span>
          </v-avatar>
          <div class="text-center">
            <span class="sw-caption sw-accent font-weight-medium">{{
              step.title
            }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    steps: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    changeStep(step) {
      if (step < this.model && !this.loading) this.model = step;
    },
    stepClass(i) {
      return [
        {
          active: i === this.model,
          complete: i < this.model,
          editable: i < this.model && !this.loading,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper-header {
  position: relative;
  margin: 0 0 73px 0;

  .v-divider {
    max-width: 80px;
    margin-top: 48px;
    border-color: $accent;
  }
}

.step {
  position: relative;
  z-index: 1;
  // min-width: 110px;
  max-width: max-content;
  background: #ffffff;
}

.step-content {
  opacity: 0.5;
}

.step.active .step-content,
.step.complete .step-content {
  opacity: 1;
}

.step.editable {
  cursor: pointer;
}
</style>
