<template>
  <v-layout class="wrap">
    <v-flex xs12 class="mb-3">
      <v-layout class="row nowrap align-center">
        <v-flex class="xs12">
          <v-combobox
            v-model="model"
            v-validate.disable="'required'"
            data-vv-name="receivers"
            :data-vv-as="$tc('receivers', 2)"
            :error-messages="errors.collect('receivers')"
            :items="receivers"
            item-text="name"
            item-value="id"
            multiple
            chips
            return-object
            no-filter
            :menu-props="{ closeOnContentClick: true }"
            :search-input.sync="search"
            :placeholder="$tc('receivers', 1)"
            hide-details
          >
            <template slot="no-data">
              <div class="px-3 py-2" :style="{ 'font-size': '13px' }">
                <span v-if="isLoading">{{ $t("searching") }}</span>
                <span v-if="!isLoading">{{ $t("nothingFound") }}</span>
              </div>
            </template>
            <template slot="selection" slot-scope="data">
              <v-chip
                v-if="data.item.id"
                :selected="data.selected"
                close
                class="chip--select-multi"
                @input="removeItem(data.item)"
              >
                <span class="caption">
                  <font-awesome-icon icon="user-tie" />
                </span>
                <span class="ml-2">{{ data.item.name }}</span>
              </v-chip>
            </template>
            <template slot="item" slot-scope="data">
              <v-layout class="row wrap align-center slot-item">
                <v-flex class="xs6">
                  <span class="body-2">{{ data.item.name }}</span>
                </v-flex>
                <v-flex class="xs6 text-right">
                  <span class="caption">{{ data.item.email }}</span>
                </v-flex>
              </v-layout>
            </template>
          </v-combobox>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 class="mb-3">
      <v-layout class="row nowrap">
        <template v-for="(lang, i) in groupSupportedLanguages">
          <v-checkbox
            :key="`lang-${i}`"
            v-model="messageLanguages[i]"
            :value="lang"
            hide-details
            class="shrink mt-0 mr-3"
          >
            <template v-slot:label>
              <span :class="`flag-icon flag-icon-${getIso2(lang)}`"></span>
            </template>
          </v-checkbox>
        </template>
      </v-layout>
    </v-flex>
    <v-flex xs6>
      <v-btn
        block
        round
        class="sw-secondary-bg sw-on-secondary text-none mx-0"
        @click="sendTestMessage"
        :loading="isLoading"
        :disabled="!selectedReceivers.length || !selectedLanguages.length"
      >
        {{ $t("test_messages.send_test_message_button_text") }}
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import "flag-icon-css/css/flag-icon.css";
import { languageIcons } from "@/language-icons.json";

export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    messageTemplateId: {
      type: [String, Number],
      required: true,
    },
    successMessage: {
      type: Array,
      default: () => ["common.test_message_success"],
    },
  },
  data: () => ({
    isLoading: false,
    model: [],
    messageLanguages: [],
    search: null,
    timeout: null,
    groupAdministrators: [],
    groupUsers: [],
    perPage: 5,
  }),
  computed: {
    groupSupportedLanguages() {
      if (
        !this.$store.getters.activeGroup ||
        !this.$store.getters.activeGroup.attributes ||
        !this.$store.getters.activeGroup.attributes.supported_languages
      ) {
        return [];
      }
      return this.$store.getters.activeGroup.attributes.supported_languages.split(
        ",",
      );
    },
    receivers() {
      return [...this.groupAdministrators, ...this.groupUsers].slice(0, 5);
    },
    selectedReceivers() {
      return this.model.filter((el) => el.id);
    },
    selectedLanguages() {
      return this.messageLanguages.filter((el) => el);
    },
  },
  watch: {
    search(val) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.listReceivers();
      }, 500);
    },
  },
  mounted() {
    this.listReceivers();
  },
  methods: {
    removeItem(item) {
      if (!item) return;

      const index = this.model.indexOf(item);

      if (index !== -1) this.model.splice(index, 1);
    },
    transformUsersForEdit(users) {
      if (!users) return [];

      return users.map((user) => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
      }));
    },
    async listGroupAdministrators() {
      this.isLoading = true;

      const params = {
        per_page: this.perPage,
      };

      if (this.search) {
        params.search = this.search;
      }

      const response = await this.$api.groupAdministrators.list(
        this.groupId,
        params,
      );

      this.isLoading = false;

      if (!response.data.data[0]) {
        this.groupAdministrators = [];
        return;
      }

      this.groupAdministrators = this.transformUsersForEdit(response.data.data);
    },
    async listGroupUsers() {
      this.isLoading = true;

      const params = {
        per_page: this.perPage,
      };

      if (this.search) {
        params.search = this.search;
      }

      const response = await this.$api.groupUsers.list(this.groupId, params);

      this.isLoading = false;

      if (!response.data.data[0]) {
        this.groupUsers = [];
        return;
      }

      this.groupUsers = this.transformUsersForEdit(response.data.data);
    },
    listReceivers() {
      this.listGroupAdministrators();
      this.listGroupUsers();
    },
    async sendTestMessage() {
      const valid = await this.$validator.validate();

      if (!valid) return;

      this.isLoading = true;

      for await (const lang of this.selectedLanguages) {
        try {
          const params = [
            this.groupId,
            this.messageTemplateId,
            {
              user_ids: this.selectedReceivers
                ? this.selectedReceivers.map((el) => el.id).toString()
                : null,
              lang: lang,
            },
          ];

          await this.$api.groupMessageTemplates.send_test(...params);

          const message = [this.successMessage[0]];

          if (this.successMessage[1] && this.successMessage[1] === "lang") {
            message.push({ lang: this.$t(`common.${lang}_label`) });
          }

          this.$store.dispatch("addNotification", {
            message: this.$t(...message),
          });
        } catch (error) {
          this.isLoading = false;
        }
      }

      this.isLoading = false;
    },
    getIso2(key) {
      const lang = languageIcons.find((el) => el.key === key);

      return lang ? lang.iso2 : key;
    },
  },
};
</script>

<style scoped lang="scss"></style>
